.contact-form {
  .form-group {
    margin-bottom: 0;
    input,
    select {
      background-color: var(--color-light-1);
      &.ng-touched {
        &.ng-invalid {
          border-color: var(--color-danger);
        }
      }
    }
    .error-input {
      color: var(--color-danger);
      display: block;
      font-size: 1.4rem;
      height: 32px;
      opacity: 0;
      text-align: left;
      &.-is-showed {
        opacity: 1;
      }
    }
    .btn-style-two {
      .ti-reload {
        animation-direction: reverse;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-name: loading;
        animation-timing-function: ease-in-out;
        display: block;
        padding-left: 0;
      }
    }
    .error-message {
      align-items: center;
      background-color: var(--color-secondary-2);
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      margin-top: 32px;
      min-height: 60px;
      padding: 16px;
      p {
        color: var(--color-light-1);
        font-size: 1.6rem;
        margin: 0;
      }
    }
  }
  .form-success {
    text-align: center;
    .ti-email {
      color: var(--color-primary-2);
      display: block;
      font-size: 12rem;
      margin: 100px auto 0;
      min-height: 12rem;
    }
    p {
      font-size: 2rem;
      font-weight: 400;
      margin: auto;
      max-width: 400px;
      text-align: center;
    }
  }
}
