/* Spring - Software, App, Saas & Product Showcase Landing HTML5 Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style One / Style Two
5. Main Banner / One / Two / Three / Four
6. What We Offer
7. Features Section
8. Services Section
9. Products Section
10. Inventory Section
11. Blog Section
12. Testimonials One
13. Contact Section
14. Main Footer / Style One / Style Two / Style Three
15. Specific Features
16. Clients Section
17. Testimonials Two
18. Sponsors Section
19. Call To Action
20. Features Section Two
21. Specific Features
22. Overview Section
23. Download App
24. Invoice Section
25. Pricing Section
26. Call To Actions Two
27. Page Title
28. Sidebar Page Container
29. Sidebar
30. Sidebar Widgets
31. Blog Grid View
32. BLog Details
33. Comments Area
34. Comment Form
35. Styled Pagination
36. Responsive.css

**********************************************/

@import url('https://fonts.googleapis.com/css?family=Heebo:300,400,500,700,800,900');
/*
font-family: 'Heebo', sans-serif;
*/

@import url('font-awesome.css');
@import url('themify-icons.css');
@import url('linear-icons.css');
@import url('animate.css');
@import url('owl.css');
@import url('jquery.fancybox.css');
/***

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

/***

====================================================================
	Global Settings
====================================================================

 ***/

body {
  font-family: 'Heebo', sans-serif;
  font-size: 18px;
  color: #6f8ba4;
  line-height: 1.6em;
  font-weight: 400;
  background: var(--color-light-1);
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--color-primary-1);
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: 'Heebo', sans-serif;
  font-weight: 400;
  margin: 0px;
  background: none;
  line-height: 1.4em;
}

input,
button,
select,
textarea {
  font-family: 'Heebo', sans-serif;
}

textarea {
  overflow: hidden;
}

p {
  position: relative;
  line-height: 1.6em;
}

.strike-through {
  text-decoration: line-through;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.medium-container {
  max-width: 850px;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.theme-btn {
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.centered {
  text-align: center;
}

.anim-3,
.anim-3-all * {
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.anim-5,
.anim-5-all * {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.anim-7,
.anim-7-all * {
  transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
}

.btn-style-one {
  position: relative;
  padding: 12px 45px;
  line-height: 26px;
  background: var(--color-primary-1);
  font-size: 16px;
  font-weight: 400;
  color: var(--color-light-1);
  text-transform: capitalize;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}

.btn-style-one:hover {
  color: var(--color-light-1);
  background: #3b566e;
}

.btn-style-two {
  position: relative;
  padding: 10px 32px;
  line-height: 28px;
  background: #f4f9fe;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--color-primary-1);
  color: #3b566e;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}
.btn-style-two span {
  vertical-align: middle;
}

.btn-style-two .icon {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  padding-left: 10px;
}

.btn-style-two:hover {
  color: var(--color-light-1);
  background: var(--color-primary-1);
}

.btn-style-three {
  position: relative;
  line-height: 24px;
  background: #f4f9fe;
  font-size: 14px;
  font-weight: 500;
  color: #466077;
  text-transform: uppercase;
  font-family: 'Heebo', sans-serif;
}

.btn-style-three.lightbox-image .icon {
  position: relative;
  display: inline-block;
  font-size: 9px;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  font-weight: 700;
  line-height: 16px;
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  padding-left: 1px;
  margin-left: 10px;
}

.btn-style-three:hover {
  color: var(--color-primary-1);
}

.btn-style-four {
  position: relative;
  padding: 10px 32px;
  line-height: 28px;
  background: none;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #466077;
  color: #3b566e;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}

.btn-style-four .icon {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  padding-left: 10px;
}

.btn-style-four:hover {
  color: var(--color-light-1);
  background: #466077;
}

.btn-style-five {
  position: relative;
  padding: 12px 45px;
  line-height: 26px;
  background: #1fd1eb;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-light-1);
  text-transform: capitalize;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}

.btn-style-five:hover {
  color: var(--color-light-1);
  background: #3b566e;
}

.btn-style-six {
  position: relative;
  padding: 11px 30px 9px;
  line-height: 30px;
  background: #3c9bee;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light-1);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.90);
  -ms-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.90);
  -o-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.90);
  -moz-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.90);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.90);
}

.btn-style-six .icon {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 10px;
}

.btn-style-six:hover {
  color: #466077;
  background: var(--color-light-1);
}

.btn-style-seven {
  position: relative;
  padding: 11px 32px 9px;
  line-height: 28px;
  background: #f4f9fe;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #aabed0;
  color: #6f8ba4;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}

.btn-style-seven .icon {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 10px;
}

.btn-style-seven:hover {
  color: var(--color-light-1);
  background: var(--color-primary-1);
}

.btn-style-eight {
  position: relative;
  padding: 11px 32px 9px;
  line-height: 28px;
  background: var(--color-light-1);
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #aabed0;
  color: #6f8ba4;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Heebo', sans-serif;
  border-radius: 4px;
}

.btn-style-eight .icon {
  position: relative;
  font-size: 14px;
  vertical-align: middle;
  padding-right: 10px;
}

.btn-style-eight:hover {
  color: var(--color-light-1);
  background: var(--color-primary-1);
}

.theme_color {
  color: var(--color-primary-1);
}

.no-bg {
  background: none !important;
}

.light-font {
  font-weight: 300;
}

.regular-font {
  font-weight: 400;
}

.semibold-font {
  font-weight: 600;
}

.bold-font {
  font-weight: 700;
}

img {
  display: inline-block;
  max-width: 100%;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: var(--color-light-1);
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../assets/spring//icons/preloader.svg');
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: var(--color-light-1);
  font-size: 20px;
  text-transform: uppercase;
  line-height: 60px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: #222222;
  display: none;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  color: var(--color-light-1);
  background: var(--color-primary-1);
}

/***

====================================================================
	Main Header style
====================================================================

***/

.main-header {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 99;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .auto-container {
  position: relative;
  max-width: 1800px;
}

.main-header.alternate .auto-container,
.main-header.header-style-two .auto-container {
  max-width: 1200px;
}

.main-header .main-box {
  position: relative;
  left: 0px;
  top: 30px;
  width: 100%;
  padding: 0px 50px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header.header-style-two .main-box {
  top: 0px;
  background: rgba(255, 255, 255, 0.30);
}

.main-header .main-box:after {
  content: '';
  position: absolute;
  left: 0px;
  top: 100%;
  height: 0px;
  width: 100%;
  opacity: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header.fixed-header .main-box:after,
.main-header.header-style-two .main-box:after {
  opacity: 1;
}

.main-header.fixed-header .main-box {
  position: fixed;
  top: 0px;
  z-index: 999;
  opacity: 1;
  visibility: visible;
  background-color: var(--color-light-1);
}

.main-header.header-style-two.fixed-header .main-box {
  top: 0px;
  background: rgba(0, 0, 0, 0.90);
}

.main-header .main-box .outer-container {
  position: relative;
}

.main-header .main-box .logo-box {
  position: relative;
  float: left;
  left: 0px;
  padding: 15px 0px;
}

.main-header .main-box .logo-box .logo img {
  display: inline-block;
  max-width: 100%;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-header .nav-outer {
  position: relative;
  float: right;
}

.main-header .nav-outer .other-links {
  position: relative;
  float: left;
  padding-top: 20px;
  margin-left: 50px;
}

.main-header.header-style-two .nav-outer .other-links {
  margin-left: 30px;
}

.main-header .nav-outer .other-links .btn-style-one {
  padding: 7px 40px;
}

.main-header.alternate .nav-outer .other-links .theme-btn {
  text-transform: capitalize;
  padding: 7px 32px;
}

.main-header.header-style-two .nav-outer .other-links .theme-btn {
  position: relative;
  text-transform: capitalize;
  color: var(--color-light-1);
  top: 7px;
  line-height: 18px;
  font-size: 16px;
  padding: 2px 0px 0px 25px;
  border-left: 1px solid var(--color-light-1);
}

.main-header.header-style-two .nav-outer .other-links .theme-btn:hover {
  text-decoration: underline;
}

.main-menu {
  position: relative;
  float: left;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navbar-collapse {
  padding: 0px;
}

.main-menu .navigation {
  position: relative;
  font-family: 'Heebo', sans-serif;
}

.main-menu .navigation>li {
  position: relative;
  float: left;
  margin: 0px 0px 0px 40px;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  color: #51718c;
  line-height: 30px;
  text-transform: capitalize;
  letter-spacing: 0.01em;
  opacity: 1;
  padding: 25px 0px;
  font-size: 18px;
  font-weight: 400;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.header-style-two .main-menu .navigation>li>a {
  color: var(--color-light-1);
}

.main-menu .navigation>li>a:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 20px;
  width: 0px;
  border-bottom: 2px solid var(--color-primary-1);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.header-style-two .main-menu .navigation>li>a:before {
  bottom: auto;
  top: 0px;
  border-bottom-color: var(--color-light-1);
}

.main-menu .navigation>li:hover>a,
.main-menu .navigation>li.current>a,
.main-menu .navigation>li.current-menu-item>a {
  color: var(--color-primary-1);
  opacity: 1;
}

.header-style-two .main-menu .navigation>li:hover>a,
.header-style-two .main-menu .navigation>li.current>a,
.header-style-two .main-menu .navigation>li.current-menu-item>a {
  color: var(--color-light-1);
}

.main-menu .navigation>li:hover>a:before,
.main-menu .navigation>li.current>a:before,
.main-menu .navigation>li.current-menu-item>a:before {
  width: 22px;
}

.header-style-two .main-menu .navigation>li:hover>a:before,
.header-style-two .main-menu .navigation>li.current>a:before,
.header-style-two .main-menu .navigation>li.current-menu-item>a:before {
  width: 100%;
}

.main-menu .navigation>li>ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 240px;
  padding: 0px;
  z-index: 100;
  display: none;
  background: var(--color-light-1);
  border-top: 2px solid var(--color-primary-1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul.from-right {
  left: auto;
  right: 100%;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.main-menu .navigation>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  padding: 12px 20px;
  line-height: 22px;
  font-weight: 400;
  font-size: 15px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li:hover>a {
  background: #3b566e;
  color: var(--color-light-1);
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  font-family: 'FontAwesome';
  content: "\f105";
  position: absolute;
  right: 10px;
  top: 12px;
  width: 10px;
  height: 20px;
  display: block;
  line-height: 20px;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 20px;
  width: 220px;
  padding: 0px;
  z-index: 100;
  display: none;
  background: var(--color-light-1);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 12px 20px;
  line-height: 22px;
  font-weight: 400;
  font-size: 13px;
  text-transform: capitalize;
  color: #222222;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
  background: var(--color-primary-1);
  color: var(--color-light-1);
}

.main-menu .navigation>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navbar-collapse>ul li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 6px;
  width: 34px;
  height: 30px;
  border: 1px solid var(--color-light-1);
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #3b566e;
  background: var(--color-light-1);
  cursor: pointer;
  z-index: 5;
  display: none;
}

/***

====================================================================
	Main Banner style
====================================================================

***/

.main-banner {
  position: relative;
  z-index: 1;
}

/*Banner One*/

.banner-one {
  position: relative;
  padding: 250px 0px 100px;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-one .text-column,
.banner-one .image-column {
  margin-bottom: 30px;
}

.banner-one .text-column .inner {
  position: relative;
  padding-top: 100px;
}

.banner-one .text-column h4 {
  font-size: 18px;
  color: var(--color-primary-1);
  text-transform: capitalize;
  margin-bottom: 30px;
}

.banner-one .text-column h2 {
  font-size: 36px;
  font-weight: 300;
  color: #3b566e;
  margin-bottom: 15px;
}

.banner-one .text-column p {
  font-size: 18px;
  margin-bottom: 50px;
}

/*Banner Two*/

.banner-two {
  position: relative;
  padding: 270px 0px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-two:before {
  content: '';
  position: absolute;
  bottom: -300px;
  left: 0px;
  width: 100%;
  height: 437px;
  background: url('../assets/spring//background/banner-shape-1.png') left bottom no-repeat;
}

.banner-two .text-column,
.banner-two .image-column {
  margin-bottom: 30px;
}

.banner-two .text-column .inner {
  position: relative;
  padding-top: 40px;
}

.banner-two .text-column h4 {
  font-size: 18px;
  color: var(--color-primary-1);
  text-transform: capitalize;
  margin-bottom: 20px;
}

.banner-two .text-column h2 {
  font-size: 34px;
  font-weight: 400;
  color: #2a2f40;
  margin-bottom: 15px;
}

.banner-two .text-column p {
  font-size: 18px;
  margin-bottom: 40px;
}

/*Banner Three*/

.banner-three {
  position: relative;
  padding: 340px 0px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.banner-three:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 200px;
  background: url('../assets/spring/background/banner-shape-2.png') left bottom no-repeat;
}

.banner-three .image-layer {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50%;
  left: 50%;
  height: 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.banner-three .text-column {
  position: relative;
}

.banner-three .text-column .inner {
  position: relative;
}

.banner-three .text-column h4 {
  font-size: 18px;
  color: var(--color-primary-1);
  text-transform: capitalize;
  margin-bottom: 20px;
}

.banner-three .text-column h2 {
  font-size: 34px;
  font-weight: 500;
  color: #2a2f40;
  line-height: 1.3em;
  margin-bottom: 15px;
}

.banner-three .text-column p {
  font-size: 18px;
  margin-bottom: 40px;
}

.banner-three .text-column .links-box a {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
}

/*Banner Four*/

.banner-four {
  position: relative;
  padding: 280px 0px 300px;
  background-color: #f9fcfe;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.banner-four:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 250px;
  background: url('../assets/spring//background/bg-shape-ten.png') right top no-repeat;
}

.banner-four .text-column,
.banner-four .image-column {
  margin-bottom: 30px;
}

.banner-four .text-column .inner {
  position: relative;
  padding-top: 80px;
  color: var(--color-light-1);
}

.banner-four .text-column h2 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 10px;
}

.banner-four .text-column p {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 50px;
}

.banner-four .text-column .theme-btn {
  margin-right: 30px;
}

/***

====================================================================
	Heading Styles
====================================================================

***/

.sec-title {
  position: relative;
  margin-bottom: 60px;
}

.sec-title.centered {
  margin-bottom: 70px;
}

.sec-title .icon {
  position: relative;
  line-height: 1em;
  font-size: 60px;
  margin-bottom: 30px;
}

.sec-title h2 {
  position: relative;
  font-size: 30px;
  color: #3b566e;
  font-weight: 300;
  line-height: 1.4em;
}

.sec-title .desc-text {
  position: relative;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 300;
  padding-top: 15px;
}

.sec-title.centered .desc-text {
  max-width: 720px;
  margin: 0 auto;
}

.sec-title-two {
  position: relative;
  margin-bottom: 40px;
}

.sec-title-two.centered {
  margin-bottom: 70px;
}

.sec-title-two h2 {
  position: relative;
  font-size: 30px;
  color: #3b566e;
  font-weight: 400;
  line-height: 1.4em;
  padding-bottom: 20px;
}

.sec-title-two.alternate h2 {
  color: #00000e;
}

.sec-title-two h2:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  border-bottom: 3px solid #6db3f1;
}

.sec-title-two.centered h2:before {
  left: 50%;
  margin-left: -25px;
}

.sec-title-two .desc-text {
  position: relative;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 300;
  padding-top: 30px;
}

.sec-title-two.centered .desc-text {
  max-width: 550px;
  margin: 0 auto;
}

.sec-title-three {
  position: relative;
  margin-bottom: 50px;
}

.sec-title-three.centered {
  margin-bottom: 60px;
}

.sec-title-three h2 {
  position: relative;
  font-size: 30px;
  color: #3b566e;
  font-weight: 300;
  line-height: 1.4em;
}

.sec-title-three .desc-text {
  position: relative;
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 300;
  padding-top: 20px;
}

.sec-title-three.centered .desc-text {
  max-width: 720px;
  margin: 0 auto;
}

/***

====================================================================
	Products Section
====================================================================

***/

.products-section {
  position: relative;
  padding: 130px 0px;
}

.products-section .services-outer {
  position: relative;
  border: 1px solid #deeefc;
  border-radius: 3px;
}

.service-style-one {
  position: relative;
  padding: 0px;
  margin: 0px;
}

.service-style-one .inner-box {
  position: relative;
  display: block;
  padding: 45px 32px 60px;
  border-right: 1px solid #deeefc;
  background: url('../assets/spring//background/bg-shape-one.png') left top no-repeat;
}

.services-outer .service-style-one:last-child .inner-box {
  border-right: none;
}

.service-style-one .inner-box .icon {
  position: relative;
  margin-bottom: 30px;
}

.service-style-one .inner-box h3 {
  color: #3b566e;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.service-style-one .inner-box h3 a {
  color: #3b566e;
}

.service-style-one .inner-box h3 a:hover {
  color: var(--color-primary-1);
  text-decoration: underline;
}

.service-style-one .inner-box .text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
}

.service-style-one .inner-box .link-box {
  position: relative;
  text-align: right;
}

.service-style-one .inner-box .link-box a {
  color: #6f8ba4;
  font-size: 14px;
  text-transform: uppercase;
}

.service-style-one .inner-box .link-box a .icon {
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
}

.service-style-one .inner-box .link-box a:hover {
  color: var(--color-primary-1);
}

/***

====================================================================
	Features Section
====================================================================

***/

.features-section {
  position: relative;
  background: #f4f9fe;
  padding: 130px 0px 150px;
}

.features-section.alternate {
  background: var(--color-light-1);
}

.features-section .features-column {
  position: relative;
}

.features-section .carousel-column {
  position: relative;
  margin-bottom: 30px;
}

.feature-block-one {
  position: relative;
  margin-bottom: 30px;
}

.feature-block-one .inner-box {
  position: relative;
  padding-right: 30px;
  display: block;
}

.feature-block-one .inner-box .icon {
  position: relative;
  margin-bottom: 20px;
}

.feature-block-one .inner-box h3 {
  color: #3b566e;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
}

.feature-block-one .inner-box h3 a {
  color: #3b566e;
}

.feature-block-one .inner-box h3 a:hover {
  color: var(--color-primary-1);
}

.feature-block-one .inner-box .text {
  font-size: 18px;
  font-weight: 400;
}

.features-section .carousel-column .inner {
  position: relative;
  padding-left: 70px;
}

.features-section .carousel-column .inner:before {
  content: '';
  position: absolute;
  right: -130px;
  top: -110px;
  width: 290px;
  height: 290px;
  background: url('../assets/spring//background/bg-shape-two.png') center center no-repeat;
}

.features-section.alternate .carousel-column .inner:before {
  background: url('../assets/spring//background/bg-shape-three.png') center center no-repeat;
}

.image-carousel-one {
  position: relative;
  -webkit-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.15);
}

.image-carousel-one img {
  display: block;
  border-radius: 4px;
}

.image-carousel-one .owl-nav {
  display: none;
}

.image-carousel-one .owl-dots {
  position: absolute;
  left: 0px;
  bottom: -80px;
  width: 100%;
  text-align: center;
}

.image-carousel-one .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.image-carousel-one .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-primary-1);
  border-radius: 50%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.image-carousel-one .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/***

====================================================================
	Inventory Section
====================================================================

***/

.inventory-section {
  position: relative;
  background: #f4f9fe;
  padding: 130px 0px 170px;
}

.inventory-section .sec-title {
  margin-bottom: 32px;
}

.inventory-section .content-box {
  position: relative;
  max-width: 610px;
  margin: 0 auto;
}

.inventory-section .content-box:before {
  content: '';
  position: absolute;
  left: -130px;
  bottom: -110px;
  width: 290px;
  height: 290px;
  background: url('../assets/spring//background/bg-shape-two.png') center center no-repeat;
}

.inventory-section .content-box:after {
  content: '';
  position: absolute;
  right: -130px;
  top: -110px;
  width: 290px;
  height: 290px;
  background: url('../assets/spring//background/bg-shape-two.png') center center no-repeat;
}

.inventory-tabs {
  position: relative;
  z-index: 5;
}

.inventory-tabs .tab-buttons {
  position: relative;
  text-align: center;
  font-size: 16px;
  margin-bottom: 25px;
}

.inventory-tabs .tab-buttons .tab-btn {
  position: relative;
  color: #51718c;
  display: inline-block;
  text-transform: capitalize;
  line-height: 24px;
  margin: 0px 10px;
  cursor: pointer;
}

.inventory-tabs .tab-buttons .active-btn {
  color: var(--color-primary-1);
}

.inventory-tabs .tabs-content {
  position: relative;
}

.inventory-tabs .tabs-content .tab {
  position: relative;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
  -ms-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
}

.inventory-tabs .tabs-content .active-tab {
  position: relative;
  opacity: 1;
  visibility: visible;
  height: auto;
  overflow: visible;
}

/***

====================================================================
	Testimonials Section
====================================================================

***/

.testimonials-section {
  position: relative;
  background: var(--color-light-1);
  padding: 130px 0px 170px;
  border-bottom: 1px solid rgba(31, 140, 235, 0.30);
  overflow: hidden;
}

.testimonials-section .outer-container {
  position: relative;
}

.testimonials-section .outer-container:before {
  content: '';
  position: absolute;
  right: -230px;
  bottom: -160px;
  width: 660px;
  height: 670px;
  background: url('../assets/spring//background/bg-shape-four.png') center right no-repeat;
}

.testimonials-carousel-one {
  position: relative;
}

.testimonials-carousel-one .text-column {
  position: relative;
}

.testimonials-carousel-one .text-column .inner {
  position: relative;
}

.testimonials-carousel-one .text-column .text {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.4em;
  color: #3b566e;
  font-style: italic;
}

.testimonials-carousel-one .info-column .inner {
  position: relative;
  padding-top: 25px;
  text-align: right;
}

.testimonials-carousel-one .info-column .info {
  position: relative;
  text-align: left;
  display: inline-block;
  min-height: 66px;
  padding-top: 10px;
  padding-left: 96px;
  font-size: 14px;
  color: #6f8ba4;
}

.testimonials-carousel-one .info-column .info h4 {
  font-size: 17px;
  color: #3b566e;
}

.testimonials-carousel-one .info-column .info .thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 66px;
  height: 66px;
  border: 1px solid var(--color-primary-1);
}

.testimonials-carousel-one .owl-nav {
  display: none;
}

.testimonials-carousel-one .owl-dots {
  position: absolute;
  left: 0px;
  bottom: -60px;
  width: 100%;
}

.testimonials-carousel-one .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
}

.testimonials-carousel-one .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--color-primary-1);
  border-radius: 50%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.testimonials-carousel-one .owl-dots .owl-dot.active span {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/***

====================================================================
	Contact Section
====================================================================

***/

.contact-section {
  position: relative;
  background: var(--color-light-1);
  padding: 130px 0px 100px;
}

.contact-section:before {
  content: '';
  position: absolute;
  left: 0px;
  margin-left: -40px;
  top: 0px;
  width: 50%;
  height: 100%;
  background: url('../assets/spring//background/bg-shape-five.png') right center no-repeat;
}

.contact-section .sec-title {
  margin-bottom: 40px;
}

.contact-section .image-column {
  position: relative;
}

.contact-section .image-column .inner {
  position: relative;
  margin-top: 170px;
}

.contact-section .image-column .inner img {
  max-width: none;
  position: absolute;
  right: 40px;
  top: 0px;
}

.contact-section .form-column {
  position: relative;
}

/***

====================================================================
	Default Form Style
====================================================================

***/

.default-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.default-form .form-group .field-label {
  display: block;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #232323;
  font-weight: 500;
  font-size: 13px;
}

.default-form .form-group .field-label sup {
  color: #ff0000;
  font-size: 14px;
}

.default-form input[type="text"],
.default-form input[type="tel"],
.default-form input[type="email"],
.default-form input[type="password"],
.default-form select,
.default-form textarea {
  display: block;
  width: 100%;
  line-height: 28px;
  height: 50px;
  font-size: 16px;
  border: 1px solid rgba(31, 140, 235, 0.50);
  padding: 10px 25px;
  background: none;
  color: #6f8ba4;
  border-radius: 4px;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.default-form .form-group input::-webkit-input-placeholder {
  color: #6f8ba4;
}

.default-form select {
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background: var(--color-light-1) url('../assets/spring//icons/icon-select.png') right center no-repeat;
}

.default-form textarea {
  height: 160px;
  resize: none;
}

.default-form select option {
  text-indent: 25px;
}

.default-form input:focus,
.default-form select:focus,
.default-form textarea:focus {
  border-color: var(--color-primary-1);
}

.default-form input.error,
.default-form select.error,
.default-form textarea.error {
  border-color: #ff0000 !important;
}

.default-form label.error {
  display: none;
  line-height: 24px;
  padding: 5px 0px 0px;
  margin: 0px;
  text-transform: uppercase;
  font-size: 11px;
  color: #ff0000;
  font-weight: 500;
}

/***

====================================================================
	Footer Style One
====================================================================

***/

.footer-style-one {
  position: relative;
  background: #ecf4f9;
  font-size: 16px;
}

.footer-style-one.dark-version {
  background: #202631;
  color: #6f8ba4;
}

.footer-style-one .footer-upper {
  position: relative;
  padding: 100px 0px 70px;
}

.footer-style-one .footer-upper .footer-column {
  position: relative;
  margin-bottom: 30px;
}

.footer-style-one .footer-upper .footer-widget {
  position: relative;
}

.footer-style-one .footer-upper h3 {
  position: relative;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--color-primary-1);
  margin-bottom: 20px;
}

.footer-style-one.dark-version .footer-upper h3 {
  color: var(--color-light-1);
}

.footer-style-one .footer-upper .copyright {
  position: relative;
  font-size: 16px;
  font-weight: 300;
}

.footer-style-one .social-links {
  position: relative;
}

.footer-style-one .social-links li {
  position: relative;
  float: left;
  margin-right: 20px;
}

.footer-style-one .social-links li a {
  position: relative;
  display: block;
  color: #6f8ba4;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-one .social-links li a:hover {
  color: var(--color-primary-1);
}

.footer-style-one.dark-version .social-links li a:hover {
  color: var(--color-light-1);
}

.footer-style-one .links {
  position: relative;
}

.footer-style-one .links li {
  position: relative;
  margin-bottom: 5px;
}

.footer-style-one .links li:last-child {
  margin-bottom: 0px;
}

.footer-style-one .links li a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  color: #6f8ba4;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-one .links li a:hover {
  color: var(--color-primary-1);
}

.footer-style-one.dark-version .links li a:hover {
  color: var(--color-light-1);
}

.footer-style-one .info-widget .text {
  position: relative;
  color: #777777;
  line-height: 24px;
}

.footer-style-one.dark-version .info-widget .text {
  color: #6f8ba4;
}

/***
====================================================================
	Features Section Two
====================================================================

***/

.features-section-two {
  position: relative;
  padding: 0px;
  background: #f2f5f8;
}

.features-section-two .features-container {
  position: relative;
  padding: 85px 80px 10px;
  background: var(--color-light-1);
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  -ms-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  -o-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
  z-index: 2;
}

.features-section-two .text-column {
  position: relative;
  margin-bottom: 70px;
}

.features-section-two .text-column h2 {
  color: #3b566e;
  font-size: 30px;
  margin-bottom: 40px;
  padding-bottom: 15px;
}

.features-section-two .text-column h2:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  border-bottom: 2px solid #6db3f1;
}

.features-section-two .text-column .text {
  color: #466077;
  font-weight: 300;
  margin-bottom: 30px;
}

.more-link-one {
  position: relative;
}

.more-link-one a {
  position: relative;
  color: #3b566e;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.more-link-one a .icon {
  position: relative;
  margin-left: 10px;
  vertical-align: middle;
}

.more-link-one a:hover {
  color: var(--color-primary-1);
}

.features-section-two .features-column {
  position: relative;
}

.features-section-two .features-column .inner {
  position: relative;
  margin-left: 70px;
}

.features-section-two .features-column .inner:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0px;
  width: 1px;
  bottom: 80px;
  background: rgba(0, 0, 0, 0.10);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
}

.features-section-two .features-column .inner:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  margin-top: -40px;
  background: rgba(0, 0, 0, 0.10);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
  background: -ms-linear-gradient(left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.0));
}

.features-section-two .features-column .row {
  margin: 0px -40px;
}

.feature-block-two {
  position: relative;
  padding: 0px 40px;
  margin-bottom: 80px;
}

.feature-block-two .inner-box {
  position: relative;
  display: block;
}

.feature-block-two .inner-box .icon {
  position: relative;
  margin-bottom: 30px;
}

.feature-block-two .inner-box h3 {
  color: #3b566e;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.feature-block-two .inner-box h3 a {
  color: #3b566e;
}

.feature-block-two .inner-box h3 a:hover {
  color: var(--color-primary-1);
}

.feature-block-two .inner-box .text {
  font-size: 18px;
  color: #466077;
  font-weight: 300;
  text-align: justify;
}

/***
====================================================================
	Specific Feature
====================================================================

***/

.specific-feature {
  position: relative;
  padding: 130px 0px 100px;
  background: #f2f5f8;
}

.specific-feature.bg-light {
  background: #f9fbfd;
}

.specific-feature .auto-container {
  max-width: 1000px;
}

.specific-feature .row {
  margin: 0px -35px;
}

.specific-feature .text-column {
  position: relative;
  padding: 0px 35px;
  margin-bottom: 30px;
}

.specific-feature .text-column .inner {
  position: relative;
  display: block;
  padding-top: 30px;
}

.specific-feature .text-column h2 {
  color: #3b566e;
  font-size: 30px;
  margin-bottom: 40px;
  padding-bottom: 15px;
}

.specific-feature .text-column h2:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  border-bottom: 3px solid #6db3f1;
}

.specific-feature .text-column .text {
  color: #466077;
  font-weight: 300;
  margin-bottom: 40px;
}

.specific-feature .image-column {
  position: relative;
  padding: 0px 35px;
  margin-bottom: 30px;
}

.specific-feature .image-column .inner {
  position: relative;
  display: block;
}

.specific-feature .image-column .image {
  position: relative;
  display: block;
  width: 100%;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  -ms-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  -o-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
}

.specific-feature .image-column .image img {
  position: relative;
  display: block;
  width: 100%;
}

/***

====================================================================
	Sponsors Section
====================================================================

***/

.sponsors-section {
  position: relative;
  background: #f9fbfd;
  padding: 130px 0px 150px;
}

.sponsors-section .auto-container {
  max-width: 1220px;
}

.sponsors-slider-one {
  position: relative;
  margin-bottom: 60px;
}

.sponsors-slider-one li .inner {
  position: relative;
  display: block;
  background: var(--color-light-1);
  margin: 10px 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -ms-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -o-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
}

.sponsors-slider-one li img {
  position: relative;
  display: inline-block;
  width: auto;
  max-width: 100%;
}

.sponsors-slider-one .owl-nav {
  display: none;
}

.sponsors-slider-one .owl-dots {
  position: absolute;
  left: 0px;
  bottom: -70px;
  width: 100%;
  text-align: center;
}

.sponsors-slider-one .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.sponsors-slider-one .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid var(--color-primary-1);
  border-radius: 50%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.sponsors-slider-one .owl-dots .owl-dot.active span {
  background: var(--color-primary-1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/***

====================================================================
	Testimonials Section Two
====================================================================

***/

.testimonials-two {
  position: relative;
  background: #f2f5f8;
  padding: 130px 0px 190px;
}

.testimonials-two .auto-container {
  position: relative;
  max-width: 1220px;
}

.testimonial-style-two .slide-item {
  position: relative;
}

.testimonial-style-two .slide-item .inner {
  position: relative;
  padding: 40px 40px 40px 45px;
  margin: 10px 10px;
  background: var(--color-light-1) url('../assets/spring//background/bg-shape-six.png') left bottom no-repeat;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -ms-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -o-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-style-two .slide-item .quote-icon {
  position: relative;
  width: 40px;
  height: 36px;
  background: url('../assets/spring//icons/icon-quote-1.png') left top no-repeat;
  margin-bottom: 25px;
}

.testimonial-style-two .slide-item .text {
  color: #466077;
  font-weight: 300;
  margin-bottom: 45px;
}

.testimonial-style-two .slide-item .quote-info {
  position: relative;
  padding-left: 100px;
  font-size: 14px;
  min-height: 74px;
  padding-top: 10px;
}

.testimonial-style-two .slide-item .quote-info .thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 74px;
  height: 74px;
  border: 2px solid var(--color-light-1);
}

.testimonial-style-two .slide-item .quote-info h4 {
  font-size: 17px;
  color: #3b566e;
  text-transform: capitalize;
}

.testimonials-two .owl-nav {
  display: none;
}

.testimonials-two .owl-dots {
  position: absolute;
  left: 0px;
  bottom: -60px;
  width: 100%;
  text-align: center;
}

.testimonials-two .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.testimonials-two .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid var(--color-primary-1);
  border-radius: 50%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.testimonials-two .owl-dots .owl-dot.active span {
  background: var(--color-primary-1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/***

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action {
  position: relative;
  background: #f9fbfd url('../assets/spring//background/bg-shape-seven.png') 87% 55% no-repeat;
  padding: 130px 0px 100px;
}

.call-to-action .text-column {
  position: relative;
  margin-bottom: 30px;
}

.call-to-action .text-column .inner {
  position: relative;
  display: block;
}

.call-to-action .text-column h2 {
  color: #3b566e;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.4em;
  margin-bottom: 25px;
}

.call-to-action .links-column {
  position: relative;
}

.call-to-action .links-column .inner {
  position: relative;
  display: block;
  text-align: right;
  padding-top: 40px;
}

.call-to-action .links-column .inner .theme-btn {
  margin-left: 25px;
  margin-bottom: 20px;
}

/***

====================================================================
	Footer Style Two
====================================================================

***/

.footer-style-two {
  position: relative;
  background: #e1effa;
  font-size: 18px;
}

.footer-style-two .footer-upper {
  position: relative;
}

.footer-style-two .outer-box {
  position: relative;
  padding: 120px 0px 90px;
}

.footer-style-two .outer-box:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url('../assets/spring//background/bg-shape-eight.png') 100px top no-repeat;
  z-index: 1;
}

.footer-style-two .outer-box:after {
  content: '';
  position: absolute;
  top: 0px;
  right: 100%;
  margin-right: -100px;
  width: 3000px;
  height: 100%;
  background: var(--color-light-1);
}

.footer-style-two .footer-column {
  position: relative;
  margin-bottom: 30px;
  z-index: 5;
}

.footer-style-two .footer-upper .footer-widget {
  position: relative;
}

.footer-style-two .about-widget .text {
  position: relative;
  color: #466077;
  max-width: 360px;
  font-weight: 300;
}

.footer-style-two .footer-upper h3 {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
  color: #3b566e;
  margin-bottom: 20px;
}

.footer-style-two .footer-upper .copyright {
  position: relative;
  font-size: 16px;
  font-weight: 300;
  padding-top: 70px;
}

.footer-style-two .links {
  position: relative;
}

.footer-style-two .links li {
  position: relative;
  margin-bottom: 5px;
}

.footer-style-two .links li:last-child {
  margin-bottom: 0px;
}

.footer-style-two .links li a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  color: #6f8ba4;
  font-weight: 300;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-two .links li a:hover {
  color: var(--color-primary-1);
}

.footer-style-two .newsletter-widget h3 {
  font-size: 20px;
  color: #3b566e;
}

.footer-style-two .newsletter-widget .form-group {
  position: relative;
  margin: 0px;
  max-width: 470px;
}

.footer-style-two .newsletter-widget .form-group input[type="text"],
.footer-style-two .newsletter-widget .form-group input[type="email"] {
  position: relative;
  line-height: 30px;
  padding: 14px 50px 14px 25px;
  border: 1px solid var(--color-primary-1);
  background: var(--color-light-1);
  color: #101010;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.footer-style-two .newsletter-widget .form-group input:focus {
  border-color: var(--color-primary-1);
}

.footer-style-two .newsletter-widget .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  display: block;
  font-size: 24px;
  color: #777777;
  background: none;
  font-weight: normal;
}

.footer-style-two .newsletter-widget .form-group input:focus+button,
.footer-style-two .newsletter-widget .form-group button:hover {
  color: var(--color-primary-1);
}

/***
====================================================================
	What We Offer
====================================================================

***/

.what-we-offer {
  position: relative;
  padding: 130px 0px 70px;
  background: #f9fbfd;
}

.what-we-offer .auto-container {
  max-width: 1120px;
}

.what-we-offer .text-column {
  position: relative;
  margin-bottom: 50px;
}

.what-we-offer .text-column .inner {
  padding-right: 50px;
}

.what-we-offer .text-column h2 {
  color: #3b566e;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 25px;
  padding-bottom: 16px;
  margin-top: -3px;
}

.what-we-offer .text-column h2:before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 50px;
  border-bottom: 3px solid #6db3f1;
}

.what-we-offer .text-column .text {
  color: #466077;
  font-weight: 300;
}

.what-we-offer .services-column {
  position: relative;
}

.what-we-offer .services-column .row {
  margin: 0px -20px;
}

.service-style-two {
  position: relative;
  padding: 0px 20px;
  margin-bottom: 50px;
}

.service-style-two .inner-box {
  position: relative;
  display: block;
}

.service-style-two .inner-box .icon {
  position: relative;
  margin-bottom: 25px;
}

.service-style-two .inner-box h3 {
  color: #3b566e;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.service-style-two .inner-box h3 a {
  color: #3b566e;
}

.service-style-two .inner-box h3 a:hover {
  color: var(--color-primary-1);
}

.service-style-two .inner-box .text {
  font-size: 18px;
  color: #466077;
  font-weight: 300;
}

/***

====================================================================
	Key Features Section
====================================================================

***/

.key-features-section {
  position: relative;
  background: #f2f5f8;
  padding: 130px 0px 50px;
}

.key-features-section .auto-container {
  max-width: 1000px;
}

.key-feature {
  position: relative;
  margin-bottom: 50px;
}

.key-feature .image-column,
.key-feature .text-column {
  position: relative;
  margin-bottom: 30px;
}

.key-feature.image-right .text-column {
  float: left;
}

.key-feature.image-left .text-column {
  float: right;
}

.key-feature .image-column .inner {
  position: relative;
  display: block;
  text-align: center;
  min-height: 520px;
  max-width: 520px;
  margin: 0 auto;
  padding-top: 60px;
  background: url('../assets/spring//background/rigns-bg.png') center center no-repeat;
  background-size: 100% auto;
}

.key-feature .text-column .inner {
  position: relative;
  display: block;
  padding-top: 170px;
}

.key-feature.image-right .text-column .inner {
  padding-right: 70px;
}

.key-feature.image-left .text-column .inner {
  padding-left: 70px;
}

.key-feature .text-column h3 {
  font-size: 24px;
  color: #3b566e;
  font-weight: 500;
  margin-bottom: 15px;
}

.key-feature .text-column .text {
  font-size: 18px;
  color: #6f8ba4;
  font-weight: 300;
  margin-bottom: 30px;
}

.key-feature .text-column .more-link-one a {
  font-weight: 500;
  text-transform: capitalize;
}

/***

====================================================================
	Reviews Section
====================================================================

***/

.review-section {
  position: relative;
  background: #f9fbfd;
  padding: 130px 0px 100px;
}

.review-section .sec-title-two {
  margin-bottom: 50px;
}

.tabs-box {
  position: relative;
}

.tabs-box .tab-buttons {
  position: relative;
}

.tabs-box .tab-buttons .tab-btn {
  position: relative;
}

.tabs-box .tabs-content {
  position: relative;
}

.tabs-box .tabs-content .tab {
  position: relative;
  display: none;
}

.tabs-box .tabs-content .active-tab {
  display: block;
}

.review-tabs {
  position: relative;
  z-index: 5;
}

.review-tabs .buttons-box {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
}

.review-tabs .tab-buttons {
  position: relative;
  display: inline-block;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-size: 16px;
}

.review-tabs .tab-buttons .tab-btn {
  position: relative;
  color: #6f8ba4;
  float: left;
  text-transform: capitalize;
  line-height: 24px;
  margin: 0px 0px;
  font-weight: 500;
  padding: 0px 27px 10px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.review-tabs .tab-buttons .active-btn {
  color: #3b566e;
  border-bottom-color: var(--color-primary-1);
}

.review-tabs .tabs-content {
  max-width: 750px;
  margin: 0 auto;
}

.review-tabs .tabs-content .tab {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  transition: opacity 300ms ease;
  -moz-transition: opacity 300ms ease;
  -webkit-transition: opacity 300ms ease;
  -ms-transition: opacity 300ms ease;
  -o-transition: opacity 300ms ease;
}

.review-tabs .tabs-content .active-tab {
  position: relative;
  opacity: 1;
  visibility: visible;
  height: auto;
  overflow: visible;
}

.review-tabs .tabs-content .tab .image-column,
.review-tabs .tabs-content .tab .text-column {
  position: relative;
  margin-bottom: 30px;
}

.review-tabs .tabs-content .tab .image-column img {
  position: relative;
}

.review-tabs .tabs-content .tab .text-column .inner {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-left: 50px;
}

.review-tabs .tabs-content .tab .text-column .icon {
  position: relative;
  margin-bottom: 30px;
}

.review-tabs .tabs-content .tab .text-column h3 {
  font-size: 20px;
  color: #3b566e;
  font-weight: 400;
  margin-bottom: 15px;
}

.review-tabs .tabs-content .tab .text-column .text {
  font-size: 18px;
  color: #6f8ba4;
  font-weight: 300;
  margin-bottom: 30px;
}

.review-tabs .tabs-content .tab .text-column .more-link-one a {
  text-transform: capitalize;
}

/***

====================================================================
	Get App Section
====================================================================

***/

.get-app-section {
  position: relative;
  background: #fafbfd;
  padding: 130px 0px 100px;
}

.get-app-section .links-outer {
  position: relative;
  text-align: center;
}

.get-app-section .links-outer .get-app-links {
  position: relative;
  display: inline-block;
}

.get-app-section .links-outer .get-app-links li {
  position: relative;
  display: inline-block;
  margin: 0px 20px 30px;
}

.get-app-section .links-outer .get-app-links .link-box {
  position: relative;
  display: block;
  padding: 20px 20px 20px 100px;
  min-height: 100px;
  min-width: 250px;
  background: var(--color-light-1);
  border-radius: 4px;
  color: #6f8ba4;
  text-align: left;
  line-height: 1.5em;
  -webkit-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.10);
  -ms-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.10);
  -o-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.10);
  box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.10);
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.get-app-section .links-outer .get-app-links .link-box:hover {
  border-color: var(--color-primary-1);
}

.get-app-section .links-outer .get-app-links .link-box .icon-box {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
  height: 60px;
}

.get-app-section .links-outer .get-app-links .link-box .title {
  position: relative;
  padding-top: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #3b566e;
  display: block;
  text-transform: capitalize;
}

/***

====================================================================
	Footer Style Three
====================================================================

***/

.footer-style-three {
  position: relative;
  font-weight: 300;
  background: #e1effa url('../assets/spring//background/bg-shape-nine.png') center bottom no-repeat;
  background-size: 100% auto;
  font-size: 18px;
}

.footer-style-three .footer-upper {
  position: relative;
  padding: 120px 0px 90px;
}

.footer-style-three .footer-column {
  position: relative;
  margin-bottom: 30px;
  z-index: 5;
}

.footer-style-three .footer-upper .footer-widget {
  position: relative;
}

.footer-style-three .info {
  position: relative;
  margin-bottom: 20px;
}

.footer-style-three .info li {
  position: relative;
  line-height: 30px;
  color: #6f8ba4;
}

.footer-style-three .info li a {
  color: #6f8ba4;
}

.footer-style-three .info li a:hover {
  color: var(--color-primary-1);
}

.footer-style-three .social-links {
  position: relative;
}

.footer-style-three .social-links li {
  position: relative;
  float: left;
  margin-right: 20px;
}

.footer-style-three .social-links li a {
  position: relative;
  display: block;
  color: #6f8ba4;
  font-size: 16px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-three .social-links li a:hover {
  color: var(--color-primary-1);
}

.footer-style-three .footer-upper h3 {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
  color: #3b566e;
  margin-bottom: 20px;
}

.footer-style-three .links {
  position: relative;
}

.footer-style-three .links li {
  position: relative;
  margin-bottom: 5px;
}

.footer-style-three .links li:last-child {
  margin-bottom: 0px;
}

.footer-style-three .links li a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  color: #6f8ba4;
  font-weight: 300;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.footer-style-three .links li a:hover {
  color: var(--color-primary-1);
}

.footer-style-three .newsletter-widget {
  position: relative;
}

.footer-style-three .newsletter-widget h3 {
  font-size: 20px;
  font-weight: 500;
  color: #3b566e;
}

.footer-style-three .newsletter-widget .text {
  position: relative;
  color: #466077;
  font-weight: 300;
  margin-bottom: 30px;
}

.footer-style-three .newsletter-widget .form-group {
  position: relative;
  margin: 0px;
  max-width: 470px;
}

.footer-style-three .newsletter-widget .form-group input[type="text"],
.footer-style-three .newsletter-widget .form-group input[type="email"] {
  position: relative;
  line-height: 28px;
  padding: 12px 50px 12px 20px;
  border: 1px solid var(--color-primary-1);
  background: #e2eff8;
  color: #101010;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 54px;
  border-radius: 4px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.footer-style-three .newsletter-widget .form-group input:focus {
  border-color: var(--color-primary-1);
}

.footer-style-three .newsletter-widget .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 54px;
  width: 60px;
  line-height: 54px;
  display: block;
  font-size: 20px;
  color: #777777;
  background: none;
  font-weight: normal;
}

.footer-style-three .newsletter-widget .form-group input:focus+button,
.footer-style-three .newsletter-widget .form-group button:hover {
  color: var(--color-primary-1);
}

.footer-style-three .footer-bottom {
  position: relative;
  padding: 35px 0px 25px;
}

.footer-style-three .footer-bottom .bottom-column {
  position: relative;
  margin-bottom: 20px;
}

.footer-style-three .footer-bottom .copyright {
  position: relative;
  font-weight: 300;
  line-height: 30px;
}

.footer-style-three .footer-bottom .bottom-nav {
  position: relative;
  text-align: right;
}

.footer-style-three .footer-bottom .bottom-nav li {
  position: relative;
  display: inline-block;
  margin-left: 30px;
}

.footer-style-three .footer-bottom .bottom-nav li a {
  position: relative;
  color: #6f8ba4;
  line-height: 30px;
}

.footer-style-three .footer-bottom .bottom-nav li a:hover {
  color: var(--color-primary-1);
}

/***

====================================================================
	Features Section Three
====================================================================

***/

.features-section-three {
  position: relative;
  padding: 100px 0px;
  background: #f9fcfe;
}

.service-style-three {
  position: relative;
  margin-bottom: 30px;
}

.service-style-three .inner-box {
  position: relative;
  display: block;
  padding: 45px 35px;
  background: #fcfeff;
  border: 1px solid #deeefc;
  border-radius: 3px;
}

.service-style-three .inner-box .icon {
  position: relative;
  margin-bottom: 30px;
}

.service-style-three .inner-box h3 {
  color: #3b566e;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.service-style-three .inner-box h3 a {
  color: #3b566e;
}

.service-style-three .inner-box h3 a:hover {
  color: var(--color-primary-1);
  text-decoration: underline;
}

.service-style-three .inner-box .text {
  font-size: 18px;
  font-weight: 300;
}

/***

====================================================================
	Big Mac Section
====================================================================

***/

.big-mac-section {
  position: relative;
  padding: 130px 0px;
  background: var(--color-light-1) url('../assets/spring//background/bg-shape-eleven.png') right -10px no-repeat;
}

.big-mac-section .big-mac-image {
  position: relative;
  text-align: center;
  margin-bottom: 70px;
}

.big-mac-section .link-box {
  position: relative;
  text-align: center;
}

/***

====================================================================
	Clients Section
====================================================================

***/

.clients-section {
  position: relative;
  padding: 130px 0px 170px;
  background: #f9fcfe;
}

.clients-section:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url('../assets/spring//background/bg-shape-twelve.png') 0px -200px no-repeat;
}

.clients-section:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url('../assets/spring//background/bg-shape-thirteen.png') 100% 200px no-repeat;
}

.clients-section .auto-container {
  max-width: 1000px;
  position: relative;
  z-index: 1;
}

.client-block {
  position: relative;
  margin-bottom: 30px;
}

.owl-theme .client-block {
  margin: 0px;
}

.client-block .inner-box {
  position: relative;
  display: block;
  padding: 45px 35px;
  background: #fcfeff;
  border: 1px solid #deeefc;
  border-radius: 3px;
}

.client-block .inner-box .image {
  position: relative;
  margin-bottom: 30px;
}

.client-block .inner-box .image img {
  display: inline-block;
  max-width: 100%;
  width: auto;
}

.client-block .inner-box h3 {
  color: #3b566e;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 7px;
}

.client-block .inner-box h3 a {
  color: #3b566e;
}

.client-block .inner-box h3 a:hover {
  color: var(--color-primary-1);
  text-decoration: underline;
}

.client-block .inner-box .text {
  font-size: 18px;
  font-weight: 300;
}

.clients-carousel .owl-nav {
  display: none;
}

.clients-carousel .owl-dots {
  position: absolute;
  left: 0px;
  bottom: -70px;
  width: 100%;
  text-align: center;
}

.clients-carousel .owl-dots .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.clients-carousel .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid var(--color-primary-1);
  border-radius: 50%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.clients-carousel .owl-dots .owl-dot.active span {
  background: var(--color-primary-1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

/***

====================================================================
	Invoice Section
====================================================================

***/

.invoice-section {
  position: relative;
  padding: 130px 0px 0px;
  background: #202631;
  color: var(--color-light-1);
  text-align: center;
}

.invoice-section .sec-title-three h2 {
  color: var(--color-light-1);
}

/***

====================================================================
	Plans Section
====================================================================

***/

.plans-section {
  position: relative;
  padding: 130px 0px 100px;
  background: #f9fcfe url('../assets/spring//background/bg-shape-fourteen.png') 105% -153px no-repeat;
}

.plans-section .big-mac-image {
  position: relative;
  text-align: center;
  margin-bottom: 70px;
}

.plans-section .link-box {
  position: relative;
  text-align: center;
}

.plans-section .title-column {
  position: relative;
  margin-bottom: 30px;
}

.plans-section .title-column .inner {
  position: relative;
  padding-top: 150px;
  max-width: 350px;
}

.plans-section .title-column h2 {
  color: #3b566e;
  font-weight: 400;
  margin-bottom: 12px;
}

.plans-section .title-column .text {
  font-weight: 300;
}

.plans-section .plans-col {
  position: relative;
}

.plans-section .plans-col .row {
  margin: 0px -20px;
}

.plan-column {
  position: relative;
  margin-bottom: 30px;
}

.plans-section .plans-col .row .plan-column {
  padding: 0px 20px;
}

.plan-column .inner-box {
  position: relative;
  display: block;
  padding: 60px 30px;
  background: #fdfeff;
  text-align: center;
  border: 1px solid #deeefc;
  border-radius: 3px;
}

.plan-column .inner-box .icon {
  position: relative;
  margin-bottom: 40px;
}

.plan-column .inner-box h3 {
  color: #3b566e;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.plan-column.inner-box h3 a {
  color: #3b566e;
}

.plan-column .inner-box h3 a:hover {
  color: var(--color-primary-1);
  text-decoration: underline;
}

.plan-column .inner-box .text {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}

/***

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action-two {
  position: relative;
  background: #f2f8fd;
  padding: 130px 0px 100px;
}

.call-to-action-two .text-column {
  position: relative;
  margin-bottom: 30px;
}

.call-to-action-two .text-column .inner {
  position: relative;
  display: block;
}

.call-to-action-two .text-column h3 {
  color: #3b566e;
  font-weight: 300;
  line-height: 1.4em;
  font-size: 30px;
  font-style: italic;
}

.call-to-action-two .links-column {
  position: relative;
  margin-bottom: 30px;
}

.call-to-action-two .links-column .inner {
  position: relative;
  text-align: right;
  padding-top: 20px;
  display: block;
}

/***

====================================================================
	Page Title Style
====================================================================

***/

.page-title {
  position: relative;
  padding: 210px 0px 120px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
}

.page-title:before {
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--color-primary-1);
  opacity: 0.90;
}

.page-title .auto-container {
  position: relative;
  z-index: 1;
}

.page-title h1 {
  position: relative;
  font-size: 48px;
  font-weight: 400;
  color: var(--color-light-1);
  text-transform: uppercase;
  line-height: 1.4em;
  word-spacing: 5px;
}

/***

====================================================================
	Blog Section
====================================================================

***/

.blog-section {
  position: relative;
  background: var(--color-light-1);
  padding: 130px 0px;
}

.news-style-one {
  position: relative;
  margin-bottom: 60px;
}

.news-style-one .inner-box {
  position: relative;
  display: block;
}

.news-style-one .inner-box .image-box img {
  display: block;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-style-one .inner-box:hover .image-box img {
  opacity: 0.70;
}

.news-style-one .lower-content {
  position: relative;
  padding: 25px 0px 0px;
}

.news-style-one .lower-content .post-meta {
  line-height: 24px;
  color: #696969;
  font-size: 16px;
  margin-bottom: 10px;
}

.news-style-one .lower-content h3 {
  font-size: 20px;
  font-weight: 500;
  color: #3b566e;
  margin-bottom: 12px;
}

.news-style-one .lower-content h3 a {
  color: #3b566e;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.news-style-one .lower-content h3 a:hover {
  color: var(--color-primary-1);
}

.news-style-one .lower-content .text {
  line-height: 1.7em;
}

.news-style-one .lower-content .text p {
  line-height: 1.7;
  margin-bottom: 20px;
}

.news-style-one blockquote {
  font-size: 18px;
  font-style: italic;
  padding: 22px 30px;
  background: #f1f1f1;
  border-left: 5px solid var(--color-primary-1);
  margin: 30px 0px 30px 50px;
}

.blog-section .text-center .theme-btn {
  margin-top: 30px;
  min-width: 220px;
}

.sidebar-page-container .social-links-one {
  position: relative;
  float: left;
}

.sidebar-page-container .social-links-one li {
  position: relative;
  float: left;
  margin-right: 15px;
}

.sidebar-page-container .post-options {
  position: relative;
  padding: 30px 0px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 30px;
}

.sidebar-page-container .post-options .share-options {
  position: relative;
  float: left;
}

.sidebar-page-container .post-options .share-options strong {
  position: relative;
  float: left;
  padding: 5px 30px 5px 0px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #3b566e;
  font-family: 'Heebo', sans-serif;
}

.sidebar-page-container .social-links-one li a {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  color: var(--color-primary-1);
  font-size: 14px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.sidebar-page-container .social-links-one li a:hover {
  background: var(--color-primary-1);
  color: var(--color-light-1);
}

.sidebar-page-container .post-options .next-option {
  position: relative;
  float: right;
}

.sidebar-page-container .post-options .next-option a {
  position: relative;
  float: left;
  padding: 5px 0px 5px 0px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  color: #3b566e;
  font-family: 'Heebo', sans-serif;
}

.sidebar-page-container .post-options .next-option a .fa {
  font-weight: normal;
  padding-left: 10px;
  font-size: 15px;
}

.sidebar-page-container .post-options .next-option a:hover {
  color: var(--color-primary-1);
}

/***

====================================================================
	Styled Pagination
====================================================================

***/

.styled-pagination {
  position: relative;
  font-family: 'Heebo', sans-serif;
}

.styled-pagination ul {
  position: relative;
  display: inline-block;
}

.styled-pagination li {
  position: relative;
  display: block;
  float: left;
  margin: 0px 15px 10px 0px;
}

.styled-pagination li a {
  position: relative;
  display: inline-block;
  line-height: 20px;
  padding: 8px 0px;
  font-size: 18px;
  text-transform: capitalize;
  background: none;
  color: #3b566e;
  font-weight: 400;
  transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
}

.styled-pagination li a .fa {
  vertical-align: middle;
  padding-left: 5px;
}

.styled-pagination li a:hover,
.styled-pagination li a.active {
  color: #072e51;
}

/***

====================================================================
	Sidebar Page
====================================================================

***/

.sidebar-page-container {
  position: relative;
  padding: 130px 0px 100px;
}

.sidebar-page-container .sidebar-side,
.sidebar-page-container .content-side {
  margin-bottom: 30px !important;
}

.sidebar {
  position: relative;
  padding-left: 20px;
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 40px;
}

.sidebar .sidebar-widget .sidebar-title {
  position: relative;
  margin-bottom: 30px;
}

.sidebar .sidebar-widget .sidebar-title h3 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.4em;
  text-transform: capitalize;
  font-weight: 500;
  color: #3b566e;
}

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 24px;
  padding: 15px 50px 15px 20px;
  border: 1px solid #f0f0f0;
  background: var(--color-light-1);
  color: #222222;
  display: block;
  font-size: 14px;
  width: 100%;
  height: 56px;
  border-radius: 2px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sidebar .search-box .form-group input:focus {
  border-color: var(--color-primary-1);
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 56px;
  width: 60px;
  line-height: 56px;
  text-align: center;
  display: block;
  font-size: 14px;
  color: #999999;
  background: #f1f1f1;
  border-radius: 0px 2px 2px 0px;
  font-weight: normal;
}

.sidebar .search-box .form-group input:focus+button,
.sidebar .search-box .form-group button:hover {
  background: var(--color-primary-1);
  color: var(--color-light-1);
}

.sidebar .popular-posts .post {
  position: relative;
  font-size: 15px;
  color: #696969;
  padding: 0px 0px;
  padding-left: 100px;
  min-height: 100px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.sidebar .popular-posts .post:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.sidebar .popular-posts .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  border-radius: 3px;
}

.sidebar .popular-posts .post:hover .post-thumb img {
  opacity: 0.70;
}

.sidebar .popular-posts .post .post-thumb img {
  display: block;
  width: 100%;
  border-radius: 3px;
}

.sidebar .popular-posts .post h4 {
  font-size: 15px;
  margin: 0px 0px 5px;
  font-weight: 500;
  color: #3b566e;
}

.sidebar .popular-posts .post a,
.sidebar .popular-posts .post a:hover {
  color: var(--color-primary-1);
}

.sidebar .popular-posts .post h4 a {
  color: #222222;
}

.sidebar .popular-posts .post-info {
  font-size: 14px;
  color: #696969;
}

.sidebar .popular-posts .post-info .fa {
  position: relative;
  padding-right: 5px;
  color: var(--color-primary-1);
  font-style: normal;
}

.sidebar .popular-tags a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 6px 22px;
  margin: 0px 8px 10px 0px;
  border: 1px solid #e0e0e0;
  color: #696969;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .popular-tags a:hover {
  background-color: var(--color-primary-1);
  border-color: var(--color-primary-1);
  color: var(--color-light-1);
}

.sidebar .list {
  position: relative;
}

.sidebar .list li {
  position: relative;
  line-height: 24px;
  border-bottom: 1px solid #f0f0f0;
}

.sidebar .list li:last-child {
  border-bottom: none;
}

.sidebar .list li a {
  position: relative;
  display: block;
  color: #696969;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  padding: 10px 0px;
}

.sidebar .list li:first-child a {
  padding-top: 0px;
}

.sidebar .list li a:hover {
  color: var(--color-primary-1);
}

/***

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area {
  position: relative;
  margin-bottom: 70px;
}

.sidebar-page-container .group-title {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-page-container .group-title h2 {
  font-size: 18px;
  text-transform: capitalize;
  color: #3b566e;
  font-weight: 500;
}

.comments-area .comment-box {
  position: relative;
}

.comments-area .comment {
  position: relative;
  margin-bottom: 40px;
}

.comments-area .comment .comment-content {
  position: relative;
  padding: 0px;
}

.comments-area .reply-comment {
  margin-left: 40px;
}

.comments-area .comment .comment-inner {
  position: relative;
  padding-left: 110px;
  min-height: 80px;
}

.comments-area .comment .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 80px;
  border-radius: 50%;
}

.comments-area .comment .author-thumb img {
  width: 100%;
  display: block;
  border-radius: 50%;
}

.comments-area .comment .comment-info {
  position: relative;
  color: #222222;
  font-size: 14px;
  font-weight: 400;
}

.comments-area .comment .comment-info strong {
  position: relative;
  display: inline-block;
  color: #3b566e;
  font-size: 15px;
  font-weight: 700;
  padding-right: 25px;
  margin-right: 25px;
  line-height: 24px;
}

.comments-area .comment .comment-info strong:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -6px;
  height: 12px;
  border-right: 2px solid #3b566e;
}

.comments-area .comment .comment-info .time {
  position: relative;
  display: inline-block;
  line-height: 24px;
}

.comments-area .comment .text {
  padding-top: 5px;
  line-height: 1.7em;
  font-size: 16px;
  text-align: justify;
  margin-bottom: 10px;
}

.comments-area .comment .reply {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #696969;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

/***

====================================================================
	Comment Form
====================================================================

 ***/

.comment-form .form-group {
  position: relative;
  margin-bottom: 25px;
}

.comment-form .form-group .field-label {
  position: relative;
  display: block;
  font-size: 14px;
  margin-bottom: 7px;
  color: #696969;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select,
.comment-form .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 30px;
  padding: 12px 25px;
  color: #696969;
  border: 1px solid #e0e0e0;
  height: 56px;
  font-size: 14px;
  background: var(--color-light-1);
  border-radius: 2px;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus {
  border-color: var(--color-primary-1);
}

.comment-form .form-group textarea {
  resize: none;
  height: 175px;
}

.comment-form button {
  position: relative;
  min-width: 180px;
  text-transform: uppercase;
}