app-home-page {
  header {
    &.main-header {
      .main-box {
        .logo-box {
          .logo {
            img {
              max-height: 40px;
              max-width: 100px;
            }
          }
        }
        .navbar-header {
          .navbar-toggle {
            border-radius: 5px;
          }
        }
      }
    }
  }
  .desc-text {
    z-index: 1;
  }
  section {
    &.main-banner {
      &.banner-one {
        padding-top: 180px;
      }
      .image-column {
        figure {
          text-align: center;
          img {
            margin: auto;
            max-width: 700px;
          }
        }
      }
    }
    &.products-section {
      h2,
      p {
        max-width: 600px;
      }
    }
    &.features-section {
      .feature-block-one {
        h3 {
          cursor: pointer;
        }
      }
      .sec-title {
        .desc-text {
          max-width: 450px;
        }
      }
      .image-carousel-one {
        $width-img: 400px;
        $max-width-img: 90vw;
        box-shadow: none;
        padding-top: 100px;
        text-align: center;
        li {
          margin: auto;
          max-width: $max-width-img;
          opacity: 1;
          transform: translateY(0);
          transition: opacity ease-in-out 0.3s, transform ease-in-out 0.3s;
          width: $width-img;
          &:nth-child(2),
          &:nth-child(3) {
            margin-top: -722px;
          }
          &.-is-hidden {
            opacity: 0;
            transform: translateY(30px);
          }
          img {
            margin: auto;
            max-width: 100%;
            width: $width-img;
          }
        }
      }
    }
    &.plans-section {
      h5 {
        font-size: 4rem;
        margin-bottom: 0;
      }
      .btn-style-seven {
        align-items: center;
        display: flex;
        justify-content: center;
        p,
        span {
          display: inline-block;
          margin: 0;
          vertical-align: middle;
          &.icon {
            line-height: 20px;
            margin-bottom: 0;
          }
        }
      }
      .per-user {
        font-size: 15px;
        margin-bottom: 20px;
        margin-top: 0;
      }
    }
  }
  footer {
    &.footer-style-one {
      .footer-upper {
        .auto-container {
          .footer-column {
            .widget-inner {
              .logo {
                text-align: center;
                width: 100px;
                img {
                  max-height: 40px;
                }
              }
            }
          }
        }
      }
      .language-column {
        text-align: center;
        p {
          line-height: 1.5;
        }
        ul {
          align-items: center;
          display: flex;
          justify-content: center;
          li {
            margin: 0 16px 0 0;
            margin-right: 16px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
