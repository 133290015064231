:root {
  --color-primary-1: #09c;
  --color-primary-2: #1ca5d3;
  --color-secondary-1: #cc6800;
  --color-secondary-2: #cc6800;
  --color-dark-1: #666;
  --color-light-1: #fff;
  --color-light-2: #fafafa;
  --color-analog-1: #40afd4;
  --color-analog-2: #007399;
  --color-analog-3: #0d637f;
  --color-danger: #e15b7e;
}